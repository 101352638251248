import { useState } from 'react'
import { filter } from 'lodash'

import { useQuery } from '@apollo/client'
import { Grid, makeStyles, Link, TableRow, TableCell } from '@material-ui/core'

import { Card, DataTableLayout, Topics, LoadingSpinner, ErrorMessage, DataUnavailable } from '@/components'
import { getOptions } from '@/components/DataTable/DateRangeFilter'
import { stateTableQuery } from '@/graphql/state'
import { StateTableChambers, StatePolicyTypes, stateInfoContentData } from '@/utils/constants'
import { useSearchParams } from '@/hooks'

const useStyles = makeStyles((theme) => ({
  link: theme.tooltiplink,
  gridItem: {
    padding: '20px 0'
  }
}))

/**
 * State Table Card
 * Table Card for States.
 * @component
 */
export default function StateTableCard() {
  const classes = useStyles()
  const infoData = filter(stateInfoContentData, (o) => o.title === 'What are the relevant legislative items?')
  const [queryData, setQueryData] = useState([])
  const [disableFilterButton, setDisableFilterButton] = useState(false)
  const [isDataEmpty, setIsDataEmpty] = useState(false)

  const { tags, endDate, startDate } = useSearchParams()

  const { loading, error } = useQuery(stateTableQuery, {
    variables: {
      tags,
      startDate,
      endDate
    },
    onCompleted: ({ statePolicyTableData: data }) => {
      setQueryData(data)
    }
  })

  // handles if there are date picker errors in the filter popover
  const handleFormErrors = (errors) => setDisableFilterButton(errors)

  // callback for data table to check if the row is expandable
  const checkIfRowExpandable = (index, rows) => {
    // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
    const filLength = rows.data.filter((d) => d.dataIndex === index).length
    const notExpandable = rows.data.length > 4 && filLength === 0
    return notExpandable ? false : true
  }

  // renders an expandable row in the data table
  const renderExpandableRow = (rowData) => {
    const colSpan = rowData.length + 1
    return (
      <TableRow>
        <TableCell colSpan={colSpan}>
          <Topics data={rowData[6]} />
        </TableCell>
      </TableRow>
    )
  }

  const columns = [
    {
      name: 'billNumber',
      label: 'Item',
      options: {
        filter: true,
        filterType: 'textField',
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const values = queryData[dataIndex]
          return values.url ? (
            <Link className={classes.link} href={values.url} target='_blank' rel='noopener'>
              {values.billNumber}
            </Link>
          ) : (
            values.billNumber
          )
        }
      }
    },
    {
      name: 'state',
      label: 'State',
      options: {
        filter: true,
        filterType: 'multiselect',
        sort: true
      }
    },
    {
      name: 'session',
      label: 'Session',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'description',
      label: 'Title and  Description',
      options: {
        filter: true,
        filterType: 'textField',
        sort: true,
        customBodyRender: (value) => <div>{value.substring(0, 970)}</div>
      }
    },
    {
      name: 'lastActionDate',
      label: 'Last Action',
      options: {
        filter: true,
        sort: true,
        filterType: 'custom',
        ...getOptions(startDate, endDate, handleFormErrors),
        customBodyRenderLite: (dataIndex) =>
          queryData[dataIndex].lastActionDate === 'Invalid date'
            ? 'Date Unavailable'
            : queryData[dataIndex].lastActionDate
      }
    },
    {
      name: 'topics',
      label: 'Topics',
      options: {
        filter: true,
        filterType: 'textField',
        sort: true,
        display: 'excluded',
        searchable: true
      }
    },
    {
      name: 'url',
      label: 'Url',
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: 'billType',
      label: 'Policy Types',
      options: {
        filter: true,
        sort: false,
        display: false,
        filterOptions: {
          names: StatePolicyTypes
        }
      }
    },
    {
      name: 'body',
      label: 'Chamber',
      options: {
        filter: true,
        sort: false,
        display: false,
        filterOptions: {
          names: StateTableChambers
        }
      }
    }
  ]

  return (
    <Card infoData={infoData} downloadDisabled>
      {error && <ErrorMessage />}
      {loading && <LoadingSpinner />}
      {!loading && queryData.length === 0 && <DataUnavailable />}
      {queryData.length > 0 && !error && !loading && (
        <Grid item xs className={classes.gridItem}>
          <DataTableLayout
            columns={columns}
            data={queryData}
            otherOptions={{
              disableFilterButton,
              isRowExpandable: checkIfRowExpandable,
              renderExpandableRow,
              expandableRows: true
            }}
          />
        </Grid>
      )}
    </Card>
  )
}
