import { Typography, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { HelpButton } from '.'

const getHeaderStyles = () => {
  const navStyle = {
    color: 'white',
    fontSize: 55
  }
  return navStyle
}

const getsubHeaderStyles = () => {
  const navStyle = {
    color: 'white',
    marginTop: '20px'
  }
  return navStyle
}

const useStyles = makeStyles(() => ({
  focusAreasTitle: {
    margin: '3em auto 0em',
    width: '33%'
  },
  divider: {
    backgroundColor: '#ebbc2f',
    width: 250,
    margin: '.8em auto .7em',
    borderWidth: 1,
    borderColor: '#ebbc2f',
    borderStyle: 'solid'
  }
}))

/**
 * Displays the title bar for the home page
 * @component
 */
const HeaderBar = () => {
  const classes = useStyles()
  return (
    <>
      <div style={{ width: '100%' }}>
        <Typography style={getHeaderStyles()} variant='h2'>
          Cancer Issue and Policy Scanner
        </Typography>
      </div>
      <div style={{ width: '100%' }}>
        <Typography variant='h5' style={getsubHeaderStyles()}>
          Discover and track legislation related to cancer
        </Typography>
      </div>
      <div style={{ width: '100%' }} className={classes.focusAreasTitle}>
        <Typography variant='h3' style={getsubHeaderStyles()}>
          Explore focus areas
        </Typography>
        <Divider classes={{ root: classes.divider }} />
      </div>
    </>
  )
}

export default HeaderBar
