import { Grid, LinearProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'

import { useSelectedTab } from '@/hooks'
import { getFormattedNumber } from '@/utils/helper'
import { BillStatusEnum } from '@/utils/constants'

const useStyles = makeStyles((theme) => ({
  breakdownTitle: theme.datafilter,
  breakdownList: {
    textAlign: 'left',
    color: 'white',
    fontSize: '1.2em'
  },
  breakdownWrapper: {
    backgroundColor: '#49495F',
    paddingTop: '1rem',
    paddingBottom: '1.47rem',
    paddingLeft: '2rem'
  },
  barStatistic: theme.progressbartitle,
  content: {
    paddingRight: '0.5rem',
    paddingTop: '1rem'
  },
  barRoot: theme.progressbar.root,
  nationalProgressBar: theme.progressbar.nationalBar,
  stateProgressBar: theme.progressbar.stateBar
}))

/**
 * Shows a break of the different bill statuses
 * @component
 */
export default function StatusBreakdown({ items }) {
  const classes = useStyles()
  const maxCount = Math.max(...items.map((o) => o.count))
  const [selectedTab] = useSelectedTab()

  const hasNumb = items.map((x) => x.dimension)
  const finalList = orderBy(items, ['count'], ['desc'])

  for (const status of Object.values(BillStatusEnum)) {
    if (!hasNumb.includes(status)) {
      finalList.push({ dimension: status, count: 0 })
    }
  }

  return (
    <Grid item>
      <div className={classes.breakdownWrapper}>
        <Typography className={classes.breakdownTitle}>STATUS</Typography>
        <div className={classes.breakdownList}>
          {finalList.map((countObj) => {
            const { dimension, count } = countObj
            const normalizedValue = (count * 100) / maxCount
            return (
              <div key={dimension}>
                <Grid container>
                  <Typography variant='h1' classes={{ root: classes.barStatistic, h1: classes.content }}>
                    {dimension}
                  </Typography>
                  <Typography variant='h1' classes={{ root: classes.barStatistic, h1: classes.content }}>
                    {getFormattedNumber(count, 0, 1)}
                  </Typography>
                </Grid>
                <LinearProgress
                  classes={{
                    root: classes.barRoot,
                    bar: selectedTab === 0 ? classes.nationalProgressBar : classes.stateProgressBar
                  }}
                  variant='determinate'
                  value={isNaN(normalizedValue) ? 0 : normalizedValue}
                />
              </div>
            )
          })}
        </div>
      </div>
    </Grid>
  )
}

StatusBreakdown.propTypes = {
  /**
   * List of counts for each policy status
   */
  items: PropTypes.array
}

StatusBreakdown.defaultProps = {
  items: []
}
