import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { filter, omit } from 'lodash'

import { PercentChangeKpi, ItemsOverTime } from './components'
import { Card, SingleSelect, LoadingSpinner, ErrorMessage, KPI, DataUnavailable } from '@/components'
import { nationalPolicyItemsQuery } from '@/graphql/national/index.js'
import { useSearchParams } from '@/hooks'
import { formatPolicyItemsChart } from '@/utils/formatNivo'
import { chamberOptions, statusOptions, typeOptions, BillStatusEnum } from '@/utils/constants'

const useStyles = makeStyles({
  chartTitle: {
    color: '#fff',
    letterSpacing: '0.2em',
    marginRight: '.8rem'
  },
  chartMenuContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  filterGroup: {
    flexGrow: 1
  },
  filters: {
    margin: '0 .8rem'
  }
})

/**
 * Policy Over Time Card
 * Show casing percent change YTD and line graph
 * depicting trends in policy items over time.
 * @component
 */
export default function PolicyOverTime() {
  const searchParams = useSearchParams()

  const [queryData, setQueryData] = useState(null)
  const [downloadData, setDownloadData] = useState(null)

  const [formattedData, setFormattedData] = useState(null)
  const [filterState, setFilterState] = useState({
    selectedChamber: 'All',
    selectedType: 'ALL',
    selectedStatus: 'All'
  })
  const classes = useStyles()

  const { tags, endDate, startDate } = searchParams

  const infoData = [
    {
      title: 'How has the number of legislative items changed over time?',
      description: [
        'This chart shows the number of legislative items by year for the last 5 years from the time period selected. You can filter the chart by chamber, type, and status.'
      ],
      dimension: ['[X-Axis] Date', '[Y-Axis] Number of legislative items', '[Color] Year']
    }
  ]

  let { selectedChamber, selectedType, selectedStatus } = filterState

  const { loading, error } = useQuery(nationalPolicyItemsQuery, {
    variables: {
      tags,
      endDate,
      startDate
    },
    onCompleted: (data) => {
      setQueryData(data)
    }
  })

  useEffect(() => {
    if (queryData) {
      let { selectedChamber, selectedType, selectedStatus } = filterState

      const chamberFiltered = filter(queryData.nationalPolicyItemsOverTime, (o) => {
        if (selectedChamber === 'All') return o.body === 'H' || o.body === 'S'
        return o.body === selectedChamber
      })
      const statusFiltered = filter(chamberFiltered, (o) => {
        if (selectedStatus === 'All') return o.status === 1 || o.status === 2 || o.status === 3 || o.status === 4
        return o.status === selectedStatus
      })
      const filtered = filter(statusFiltered, (o) => {
        if (selectedType === 'ALL') return o.billType === 'R' || o.billType === 'B'
        return o.billType === selectedType
      })

      const csvData = filtered.map((x) => {
        const a = omit(x, ['status', 'body', 'billType'])
        const chamber = x.body === 'S' ? 'Senate' : 'House'
        const billType = x.billType === 'B' ? 'Bills' : 'Resolutions'

        return {
          status: BillStatusEnum[x.status],
          chamber: chamber,
          billType: billType,
          ...a
        }
      })

      if (!filtered || filtered.length === 0) {
        setDownloadData([])
        setFormattedData({ data: [] })
      } else {
        setDownloadData([...csvData])
        setFormattedData(formatPolicyItemsChart(filtered, startDate, endDate))
      }
    }
  }, [filterState, queryData])

  /**
   * Handle change function that sets searchFilter.
   * @constructor
   * @param {object} e - event.
   */
  const handleChange = (e) => {
    setFilterState({
      ...filterState,
      [e.target.name]: e.target.value
    })
  }

  /**
   * Dropdown menues for cards
   * @function
   * @param {object} nameString - input prop for select
   * @param {object} options - dropDown options.
   * @param {object} value - selectedValue that is handled by onChange.
   */
  const dropDownComp = (nameString, options, value, ariaLabel) => (
    <span className={classes.filters}>
      <SingleSelect name={nameString} options={options} onChange={handleChange} value={value} ariaLabel={ariaLabel} />
    </span>
  )

  return (
    <Card
      downloadData={downloadData}
      downloadDisabled={error || loading || !queryData}
      fileName='PolicyOverTime'
      infoData={infoData}
    >
      {error && <ErrorMessage />}
      {loading && <LoadingSpinner />}
      {queryData && formattedData && (
        <>
          <KPI>
            <PercentChangeKpi data={queryData.nationalPolicyItemsOverTime} />
          </KPI>
          <Grid
            container
            item
            direction={formattedData.data.length === 0 ? 'column' : 'row'}
            style={{ flex: '1 1 65%', padding: '20px 10px 20px 30px' }}
          >
            <div className={classes.chartMenuContainer}>
              <span className={classes.chartTitle}>LEGISLATIVE ITEMS OVER TIME</span>
              <div className={classes.filterGroup}>
                {dropDownComp('selectedChamber', chamberOptions, selectedChamber, 'Select Chamber of Congress')}
                {dropDownComp('selectedType', typeOptions, selectedType, 'Select type of legislation')}
                {dropDownComp('selectedStatus', statusOptions, selectedStatus, 'Select legislation status')}
              </div>
            </div>
            {formattedData.data.length !== 0 && <ItemsOverTime lineChartData={formattedData} />}
            {formattedData.data.length === 0 && (
              <div style={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}>
                <DataUnavailable />
              </div>
            )}
          </Grid>
        </>
      )}
    </Card>
  )
}
