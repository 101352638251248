import { useQuery } from '@apollo/client'
import { faScroll } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'

import { LoadingSpinner, ErrorMessage, DataUnavailable, Card, KPI, PolicyTypeKpi, StatisticKPI } from '@/components'
import { topLevelNationalKpisQuery } from '@/graphql/national'
import { useSearchParams } from '@/hooks'
import { PolicyEnum, MasterTabEnum } from '@/utils/constants'

/**
 * National Key Performance Indicator Card
 * Showcasing bills and resolutions.
 * @component
 */
export default function NationalKPICard() {
  const [queryData, setQueryData] = useState({})
  const [isDataEmpty, setIsDataEmpty] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [downloadData, setDownloadData] = useState([])

  const infoData = [
    {
      title: 'How many national legislative items are there, and what is their status?',
      description: [
        'This section provides an overview of national legislative items that match your search choices. Legislative items are divided into bills and resolutions. Click either one to see the status groups.'
      ],
      dimension: null,
      note: null
    }
  ]

  const { tags, startDate, endDate } = useSearchParams()
  const policyType = PolicyEnum.National
  const selectedMasterTab = MasterTabEnum.NationalPolicy

  const { loading, error } = useQuery(topLevelNationalKpisQuery, {
    variables: {
      tags,
      startDate,
      endDate,
      policyType,
      selectedMasterTab
    },
    onCompleted: ({ topLevelNationalPolicyKpisData: data }) => {
      if (data) {
        setIsDataEmpty(
          data.policyCount === 0 &&
            data.billTotalCount === 0 &&
            data.billCount.length === 0 &&
            data.resolutionCount.length === 0
        )
        setQueryData({
          bills: data.billCount,
          resolutions: data.resolutionCount,
          ...data
        })
        setHasLoaded(data && data.policyCount > 0)

        // Formatting Data to download in a CSV format.
        const csvBillCount = data.billCount.map((x) => ({
          type: 'Bill',
          ...x
        }))

        const csvResCount = data.resolutionCount.map((x) => ({
          type: 'Resolution',
          ...x
        }))

        setDownloadData([...csvBillCount, ...csvResCount])
      }
    }
  })

  return (
    <Card
      downloadData={downloadData}
      downloadDisabled={error || loading || isDataEmpty}
      fileName='NationalKPI'
      infoData={infoData}
    >
      {error && <ErrorMessage />}
      {loading && <LoadingSpinner />}
      {isDataEmpty && <DataUnavailable />}
      {hasLoaded && !isDataEmpty && (
        <>
          <KPI>
            <StatisticKPI icon={faScroll} statistic={queryData.policyCount} title='NATIONAL LEGISLATIVE ITEM' />
          </KPI>
          <PolicyTypeKpi
            bills={queryData.bills}
            resolutions={queryData.resolutions}
            totalBills={queryData.billTotalCount}
            totalResolutions={queryData.resolutionTotalCount}
          />
        </>
      )}
    </Card>
  )
}
