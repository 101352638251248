import { Paper, Grid, Typography, Toolbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import { InfoButton, DownloadButton } from '.'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    paddingBottom: '3.8em',
    margin: 'auto',
    disableElevation: true,
    order: 1
  },
  paper: {
    minHeight: '20em',
    backgroundColor: '#3b3b4b',
    display: 'flex',
    flexDirection: 'column'
  },
  tabToolBar: {
    backgroundColor: '#292935'
  },
  cardTitle: {
    flexGrow: 1,
    fontSize: '1.3rem',
    color: 'white',
    textAlign: 'start',
    marginLeft: '1em',
    backgroundColor: '#292935'
  },
  contentArea: {
    flex: 1,
    justifyContent: 'space-around'
  },

  buttonDiv: {
    display: 'flex'
  }
}))

/**
 * This is used as a wrapper for all card based visualizations. Regardless of national
 * or state.
 * @component
 */
export default function Card({ children, infoData, downloadData, fileName, downloadDisabled, styles }) {
  const classes = useStyles()

  const cardTitle = infoData && infoData.length !== 0 ? infoData[0].title : ''

  return (
    <div className={classes.root}>
      <Paper elevation={1} className={classes.paper} role='region' aria-labelledby={`card-title-${cardTitle}`}>
        <Toolbar variant='dense' className={classes.tabToolBar} disableGutters>
          <Typography className={classes.cardTitle} role='heading' id={`card-title-${cardTitle}`}>
            {cardTitle}
          </Typography>
          <div className={classes.buttonDiv} id='buttonGroupDiv'>
            {!downloadDisabled && <DownloadButton data={downloadData} fileName={fileName} title={cardTitle} />}
            <InfoButton cardTitle={cardTitle} infoData={infoData} />
          </div>
        </Toolbar>
        <Grid container className={classes.contentArea} style={styles}>
          {children}
        </Grid>
      </Paper>
    </div>
  )
}

Card.propTypes = {
  /**
   * Data to be downloaded into a CSV.
   */
  downloadData: PropTypes.array,
  /**
   * Name given to file if CSV download button is clicked.
   */
  fileName: PropTypes.string,
  /**
   * Array of objects containing information about the
   * content being displayed
   */
  infoData: PropTypes.array,
  /**
   * Boolean to determine if the download button should be hidden or not
   */
  downloadDisabled: PropTypes.bool,
  /**
   * override content area styles
   */
  styles: PropTypes.object
}

Card.defaultProps = {
  infoData: [],
  downloadData: [],
  fileName: 'example',
  downloadDisabled: false,
  styles: {}
}
