import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Box } from '@material-ui/core'
import moment from 'moment'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { searchedTags } from '@/utils/helper'
import { cancerTagValues, strategicTagValues, HIDE_RESULTS_PAGE_TUTORIAL } from '@/utils/constants'
import { BackToSearch, Glossary, HelpButton } from '@/components'

const useStyles = makeStyles({
  keyword: {
    color: 'white',
    fontSize: '1.8rem'
  },
  returnDetails: {
    color: '#B8B8B8',
    fontSize: '1.1rem',
    margin: '0 auto',
    maxWidth: '60%',
    textAlign: 'center'
  },
  highlight: {
    color: 'white'
  },
  termsGroup: {
    backgroundColor: 'transparent',
    position: 'relative',
    marginBottom: 10
  },
  toggleButtonLabel: {
    color: 'white',
    textTransform: 'none'
  },
  cancerButton: {
    color: 'rgb(220,20,60)',
    backgroundColor: 'rgba(220,20,60,0.1)',
    fontSize: '1.3rem'
  },
  sdohButton: {
    color: 'rgb(235, 188, 47)',
    backgroundColor: 'rgba(235, 188, 47,0.1)',
    fontSize: '1.3rem'
  },
  strategicButton: {
    color: 'rgb(16, 192, 159)',
    backgroundColor: 'rgba(16, 192, 159,0.1)',
    fontSize: '1.3rem'
  },
  buttonContainer: {
    width: '100%',
    marginTop: '2em'
  },
  buttonDiv: {
    display: 'flex'
  }
})

/**
 * Shows the list of search terms, search date, as well as the glossary
 * and back button
 * @component
 */
export default function SearchResultsHeader({ searchParams }) {
  const classes = useStyles()
  const { tags, startDate, endDate } = searchParams
  const tagNameObjects = Object.keys(tags)
  const tagNames = []

  for (const item of tagNameObjects) {
    if (item === 'sdoh') tagNames.push('Social Determinants of Health')
    else tagNames.push(item.charAt(0).toUpperCase() + item.slice(1))
  }

  const searchedTerms = searchedTags(tagNameObjects)
  const getSearchTagColor = (searchTag) => {
    if (cancerTagValues.includes(searchTag)) {
      return classes.cancerButton
    }
    if (strategicTagValues.includes(searchTag)) {
      return classes.strategicButton
    } else {
      return classes.sdohButton
    }
  }

  return (
    <Grid container alignItems='center' justifyContent='space-between'>
      <Grid item id='backToSearch'>
        <BackToSearch />
      </Grid>
      <Grid item id='searchedItems'>
        <Grid container direction='column' alignItems='center'>
          <Grid item>
            {tagNames.map((button) => (
              <ToggleButtonGroup key={button} exclusive className={classes.termsGroup} id='toggleBtnGroup' disabled>
                <ToggleButton
                  tabIndex='-1'
                  style={{
                    cursor: 'default',
                    borderRadius: 7,
                    border: 1,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    margin: 5,
                    width: 'auto',
                    fontWeight: 'bold'
                  }}
                  classes={{
                    root: getSearchTagColor(button),
                    selected: classes.toggleButtonSelected,
                    label: classes.toggleButtonLabel
                  }}
                  key={button}
                  value={button}
                  disableRipple
                >
                  {button}
                </ToggleButton>
              </ToggleButtonGroup>
            ))}
          </Grid>
          <Grid item>
            <Typography className={classes.returnDetails}>
              Displaying results for{' '}
              <span>
                {searchedTerms.map((term, i) => (
                  <Fragment key={`${term}-term`}>
                    <span className={classes.highlight}>
                      <i>{term}</i>
                    </span>
                    {i !== searchedTerms.length - 1 && <span> and </span>}
                  </Fragment>
                ))}
              </span>{' '}
              for the period of{' '}
              <span className={classes.highlight}>{moment.utc(startDate).format('MMMM D, YYYY')}</span> to{' '}
              <span className={classes.highlight}>{moment.utc(endDate).format('MMMM D, YYYY')}</span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <div className={classes.buttonDiv}>
          <HelpButton localStorageText={HIDE_RESULTS_PAGE_TUTORIAL} isHomeTut={false} />
          <Glossary className={classes.glossaryIcon} />
        </div>
      </Grid>
    </Grid>
  )
}

SearchResultsHeader.propTypes = {
  /**
   * List of selected terms and dates from the landing page
   */
  searchParams: PropTypes.object.isRequired
}
