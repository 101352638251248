import Helmet from 'react-helmet'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import { HeaderCDC, Footer } from '@/components'
import './Default.css'

export default function Default({ children }) {
  return (
    <>
      <Helmet
        title='Division of Cancer Prevention and Control'
        meta={[
          { charset: 'utf-8' },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge'
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1'
          }
        ]}
      />
      <HeaderCDC />
      <div className='App'>{children}</div>
      <Footer />
    </>
  )
}
