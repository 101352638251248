import { useEffect, useState } from 'react'
import { filter, sumBy } from 'lodash'

import { useQuery } from '@apollo/client'
import { Card, LoadingSpinner, ErrorMessage, KPI, PolicyTypeKpi, DataUnavailable } from '@/components'
import { topLevelStatePolicyQuery } from '@/graphql/state'
import { useSearchParams } from '@/hooks'
import { PolicyEnum, stateInfoContentData } from '@/utils/constants'
import { groupDataBystatus } from '@/utils/helper'

import { StateStatisticKPI } from './components'

/**
 * State Key Performance Indicator Card. Showcasing bills and resolutions.
 * @component
 */
export default function StateKPICard() {
  const [queryData, setQueryData] = useState(null)
  const [statesList, setStatesList] = useState(null)

  const [bills, setBills] = useState(null)
  const [totalBills, setTotalBills] = useState(0)
  const [resolutions, setResolutions] = useState(null)
  const [totalResolutions, setTotalResolutions] = useState(0)

  const [hasLoaded, setHasLoaded] = useState(false)
  const [downloadData, setDownloadData] = useState([])
  const [selectedState, setSelectedState] = useState('All')
  const [isDataEmpty, setIsDataEmpty] = useState(false)

  // Variables for useQuery
  const { tags, startDate, endDate } = useSearchParams()
  const policyType = PolicyEnum.State

  const infoData = filter(
    stateInfoContentData,
    (o) => o.title === 'How many state legislative items are there, and what is their status?'
  )

  const handleStateSelect = (e, selected) => {
    setSelectedState(selected !== null ? selected.props.value : 'All')
  }

  const { loading, error } = useQuery(topLevelStatePolicyQuery, {
    variables: {
      tags,
      startDate,
      endDate,
      policyType
    },
    onCompleted: ({ topLevelStatePolicyKpisData: data }) => {
      setIsDataEmpty(
        data.policyCount === 0 &&
          data.billTotalCount.length === 0 &&
          data.billCount.length === 0 &&
          data.resolutionCount.length === 0
      )

      setQueryData({
        bills: data.billCount,
        resolutions: data.resolutionCount,
        ...data
      })

      const bills = groupDataBystatus(data.billCount)
      setBills(bills)
      setTotalBills(sumBy(bills, 'count'))

      const resolutions = groupDataBystatus(data.resolutionCount)
      setResolutions(resolutions)
      setTotalResolutions(sumBy(resolutions, 'count'))

      setStatesList(data.states)
      setHasLoaded(data && data.billCount && data.resolutionCount && data.states)

      // Formatting Data to download in a CSV format.
      const csvBillCount = data.billCount.map((x) => ({
        type: 'Bill',
        ...x
      }))

      const csvResCount = data.resolutionCount.map((x) => ({
        type: 'Resolution',
        ...x
      }))

      setDownloadData([...csvBillCount, ...csvResCount])
    }
  })

  useEffect(() => {
    if (queryData) {
      const a = selectedState !== 'All' ? filter(queryData.bills, (o) => o.name === selectedState) : queryData.bills

      const b =
        selectedState !== 'All' ? filter(queryData.resolutions, (o) => o.name === selectedState) : queryData.resolutions

      // Formatting Data to download in a CSV format.
      const csvBillCount = a.map((x) => ({
        type: 'Bill',
        ...x
      }))

      const csvResCount = b.map((x) => ({
        type: 'Resolution',
        ...x
      }))

      setDownloadData([...csvBillCount, ...csvResCount])

      const bills = groupDataBystatus(a)
      setBills(bills)
      setTotalBills(sumBy(bills, 'count'))

      const resolutions = groupDataBystatus(b)
      setResolutions(resolutions)
      setTotalResolutions(sumBy(resolutions, 'count'))
    }
  }, [selectedState])

  return (
    <Card
      downloadData={downloadData}
      downloadDisabled={error || loading || !hasLoaded}
      fileName='StateKPI'
      infoData={infoData}
    >
      {error && <ErrorMessage />}
      {loading && <LoadingSpinner />}
      {isDataEmpty && <DataUnavailable />}
      {hasLoaded && !isDataEmpty && !error && (
        <>
          <KPI>
            <StateStatisticKPI
              onSelect={handleStateSelect}
              selectedState={selectedState}
              statistic={queryData.policyCount}
              stateCount={queryData.stateCount}
              states={statesList}
            />
          </KPI>
          <PolicyTypeKpi
            bills={bills}
            resolutions={resolutions}
            totalBills={totalBills}
            totalResolutions={totalResolutions}
          />
        </>
      )}
    </Card>
  )
}
