import { Typography, ButtonBase } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import Pluralize from 'react-pluralize'
import { getFormattedNumber } from '@/utils/helper'

const containerStyles = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  width: '100%'
}

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center'
  },
  statContainer: containerStyles,
  clickedContainer: {
    backgroundColor: '#49495F',
    ...containerStyles
  },
  statistic: theme.kpiSubStatistic,
  subTitle: theme.kpiTitle
}))

/**
 * A reusable component for making a large clickable button that shows
 * a KPI value
 * @component
 */
export default function ClickableKPI({ count, name, onClick, selected }) {
  const classes = useStyles()

  return (
    <ButtonBase focusRipple onClick={onClick} style={{ height: '100%', width: '100%' }}>
      <div className={selected ? classes.clickedContainer : classes.statContainer}>
        <Typography className={classes.statistic} variant='h1'>
          {getFormattedNumber(count, 0, 1)}
        </Typography>
        <Typography className={classes.subTitle} variant='h1'>
          <Pluralize className={classes.subTitle} singular={name} count={count} showCount={false} />
        </Typography>
      </div>
    </ButtonBase>
  )
}

ClickableKPI.propTypes = {
  /**
   * total number for this KPI
   */
  count: PropTypes.number,
  /**
   * name of this KPI
   */
  name: PropTypes.string,
  /**
   * handle when the KPI is clicked
   */
  onClick: PropTypes.func.isRequired,
  /**
   * is this is currently the active KPI
   */
  selected: PropTypes.bool
}

ClickableKPI.defaultProps = {
  count: 0,
  name: '',
  selected: false
}
