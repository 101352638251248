import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'

import { Tutorial, MobilePopup } from '@/components'

import { StateKPI, StateTable, StateMapCard, PolicyByState } from './Cards'
import { useMapFeedStatus } from '@/hooks'

export default function StateDashboard() {
  const [, updateMapFeedStatus] = useMapFeedStatus()

  useEffect(() => {
    updateMapFeedStatus(false)
  }, [])

  return (
    <div id='State Dashboard'>
      {isMobile ? (
        <MobilePopup />
      ) : (
        <>
          <Tutorial isHomeTut={false} />
          <StateKPI />
          <StateMapCard />
          <PolicyByState />
          <StateTable />
        </>
      )}
    </div>
  )
}
