import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useSearchParams } from '@/hooks'
import { searchedTags } from '@/utils/helper'
import { DataUnavailable } from '@/components'

const useStyles = makeStyles((theme) => ({
  mapKpiTitle: theme.kpiTitle,
  dataStory: theme.kpidescription,
  Kpi: theme.kpiStatistic
}))

export default function KpiMap({ stateCount, congressCount, topState }) {
  const classes = useStyles()
  const searchParams = useSearchParams()
  const tagNames = Object.keys(searchParams.tags)
  const searchedTerms = searchedTags(tagNames)

  return stateCount === 0 ? (
    <DataUnavailable />
  ) : (
    <>
      <Typography className={classes.Kpi} variant='h1'>
        {stateCount}
      </Typography>
      <Typography className={classes.mapKpiTitle} align='center'>
        REPRESENTED STATES AND TERRITORIES
      </Typography>

      <Typography className={classes.dataStory}>
        {stateCount} states and territories were represented by {congressCount} congress members sponsoring legislative
        items about <i>{searchedTerms}</i>. The most represented state was {topState.name} with {topState.count}{' '}
        {topState.count > 1 ? 'member' : 'members'}.
      </Typography>
    </>
  )
}
