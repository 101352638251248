import { createTheme } from '@material-ui/core/styles'
import { theme } from '@/style/theme'
const dark = '#3B3B4B'

export const getMuiTheme = () =>
  createTheme({
    ...theme,
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: 'transparent'
        },
        paper: {
          backgroundColor: 'transparent',
          borderRadius: 6,
          borderWidth: 'thin',
          borderStyle: 'groove',
          borderColor: '#FFFFFF',
          width: '97.5%',
          marginLeft: '1.2%',
          marginRight: '0.3%'
        }
      },
      MUIDataTableHeadCell: {
        data: {
          fontWeight: 'bold',
          fontSize: '0.9rem'
        },
        toolButton: {
          backgroundColor: 'transparent',
          color: '#FFFFFF'
        },
        sortActive: {
          fill: '#FFFFFF',
          color: '#FFFFFF'
        },
        sortAction: {
          '& svg': {
            fill: '#FFFFFF'
          }
        }
      },
      MUIDataTablePagination: {
        root: {
          color: 'white'
        },
        toolbar: {
          '& button': {
            color: 'white'
          },
          '& button.Mui-disabled': {
            color: '#ffffff33'
          }
        }
      },
      MUIDataTableBodyCell: {
        root: {
          backgroundColor: 'transparent',
          color: '#FFFFFF'
        }
      },
      MuiCheckbox: {
        root: {
          color: 'white',
          '&.Mui-checked': {
            color: 'white'
          }
        },
        colorPrimary: {
          color: 'white',
          '&.Mui-checked': {
            color: 'white'
          }
        }
      },
      MUIDataTableSearch: {
        searchIcon: { color: '#FFFFFF' },
        clearIcon: { color: 'white' }
      },
      MUIDataTableSelectCell: {
        icon: {
          color: 'white'
        }
      },
      MUIDataTableToolbar: {
        root: {
          color: '#FFFFFF'
        },
        icon: {
          color: 'white',
          '&:hover': {
            color: 'white'
          }
        },

        filter: {
          color: 'red'
        },

        iconActive: {
          color: 'white'
        },
        filterPaper: {
          backgroundColor: 'rgba(34,34,44,0.95)'
        },
        filterCloseIcon: {
          color: 'white'
        }
      },
      MuiTableCell: {
        body: {
          color: '#FFFFFF',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          fontSize: '0.9rem'
        }
      },
      MUIDataTableFilter: {
        title: {
          color: 'rgb(208,206,206)'
        },
        resetLink: {
          color: 'rgb(208,206,206)'
        }
      },
      MuiFormLabel: {
        root: {
          '&$focused': { color: 'rgb(208,206,206)' },
          color: 'rgb(208,206,206)'
        }
      },
      MuiListItemText: {
        root: {
          color: 'rgb(208,206,206)'
        }
      },
      MuiInputBase: {
        root: {
          color: 'rgb(208,206,206)'
        },
        formControl: {
          color: 'rgb(208,206,206)'
        }
      },
      MuiInput: {
        underline: {
          '&::before': {
            borderBottomColor: 'rgb(208,206,206)'
          },
          '&::after': {
            borderBottomColor: 'rgb(208,206,206)'
          }
        }
      },
      MuiSelect: {
        icon: {
          color: 'rgb(208,206,206)'
        }
      },
      MuiMenuItem: {
        root: {
          color: 'rgb(208,206,206)'
        }
      },
      MuiChip: {
        root: {
          color: '#FFFFFF',
          backgroundColor: 'rgb(41,41,53)',
          borderStyle: 'solid',
          borderColor: 'rgb(24,24,30)'
        },
        deleteIcon: {
          color: 'rgb(96,95,101)'
        }
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          color: 'white',
          '& button.Mui-disabled': {
            '& span': {
              color: '#ffffff33'
            }
          }
        },
        dayLabel: {
          color: 'white'
        },
        iconButton: {
          color: 'white'
        }
      },
      MuiPickersDay: {
        day: {
          color: 'white'
        },
        daySelected: {
          backgroundColor: 'white',
          color: dark,
          '&:hover': {
            color: dark,
            backgroundColor: 'white'
          }
        },
        dayDisabled: {
          color: '#ffffff33',
          backgroundColor: 'transparent'
        },
        current: {
          color: 'white'
        }
      }
    }
  })
