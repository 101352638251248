import { LineChart } from '@/components'

export default function ItemsOverTime({ lineChartData }) {
  const { legends, axisBottom, data, colors } = lineChartData
  const formatTicks = { format: (e) => Math.floor(e) === e && e }
  return (
    <LineChart
      data={data}
      axisBottom={axisBottom}
      enableArea={false}
      colors={colors}
      legends={legends}
      height={375}
      format={formatTicks}
    />
  )
}
