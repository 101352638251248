import { makeStyles } from '@material-ui/core'
import { ResponsiveHeatMap } from '@nivo/heatmap'
import PropTypes from 'prop-types'
import { useEffect, useState, useRef } from 'react'

import { StateHeatMapYears, StatesEnum } from '@/utils/constants'

const useStyles = makeStyles({
  root: {
    padding: 0,
    width: '95%',
    height: 375,
    overflowY: 'scroll',
    marginTop: 10
  }
})

/**
 * Wrapper for displaying a nivo heatmap chart
 * @component
 */
export default function Heatmap({ data, maxValue }) {
  const classes = useStyles()
  const colors = ['#324c63', '#1e748c', '#009fa2', '#4fc8a3']
  const [rightMargin, setRightMargin] = useState(10)
  const chartWrapper = useRef(null)

  useEffect(() => {
    if (chartWrapper.current && chartWrapper.current.scrollHeight > chartWrapper.current.clientHeight) {
      // scroll bar is visible
      setRightMargin(chartWrapper.current.offsetWidth - chartWrapper.current.children[0].offsetWidth)
    }
  }, [chartWrapper.current])

  /**
   * This creates the individual squares on the heatmap nivo chart
   * @component
   */
  const CustomCell = ({ value, x, y, color, opacity, textColor, onHover, onLeave, theme }) => (
    <g
      transform={`translate(${x}, ${y})`}
      onMouseEnter={onHover}
      onMouseMove={onHover}
      onMouseLeave={onLeave}
      style={{ cursor: 'pointer' }}
    >
      {value === 0 ? (
        <rect x={-32} y={-12} width={65} height={25} fill='#292935' />
      ) : (
        <>
          <rect x={-32} y={-12} width={65} height={25} fill={color} />
          <text
            alignmentBaseline='central'
            textAnchor='middle'
            style={{
              ...theme.labels.text,
              fill: colors.indexOf(color) > 1 ? '#21212B' : textColor
            }}
            fillOpacity={opacity}
          >
            {value}
          </text>
        </>
      )}
    </g>
  )

  /**
   * Creates an invisible cell for the fixed header trick
   * @component
   */
  const InvisibleCell = () => <g style={{ cursor: 'none', color: 'transparent' }}></g>

  const commonProps = {
    animate: false,
    axisBottom: null,
    axisRight: null,
    borderColor: '#000000',
    borderWidth: '10',
    cellBorderColor: '#000000',
    cellHoverOthersOpacity: 1,
    cellOpacity: 1,
    colors,
    fill: [{ id: 'lines' }],
    indexBy: 'state',
    keys: StateHeatMapYears,
    labelTextColor: '#ffffff',
    padding: 2
  }

  const commonTheme = {
    textColor: 'fff',
    tooltip: {
      container: {
        background: 'rgba(34,34,44,.95)',
        borderRadius: 7,
        color: '#fff'
      }
    },
    labels: {
      text: {
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: '0.9rem'
      }
    },
    axis: {
      legend: {
        text: {
          fill: '#ffffff',
          letterSpacing: '0.2rem',
          fontFamily: 'Roboto',
          fontWeight: 300,
          fontSize: '0.9rem'
        }
      },
      ticks: {
        text: {
          fill: '#ffffff',
          fontFamily: 'Roboto',
          fontWeight: 300,
          fontSize: '0.9rem'
        }
      }
    }
  }

  return (
    <>
      <div style={{ height: 35, width: '95%' }}>
        <ResponsiveHeatMap
          {...commonProps}
          axisLeft={null}
          axisTop={{ orient: 'top', legend: '' }}
          cellShape={InvisibleCell}
          data={[data[0]]}
          height={40}
          margin={{ top: 40, right: 5 + rightMargin, left: 30 }}
          theme={commonTheme}
        />
      </div>
      <div className={classes.root} ref={chartWrapper}>
        <ResponsiveHeatMap
          {...commonProps}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'States',
            legendPosition: 'middle',
            legendOffset: -40
          }}
          axisTop={null}
          cellShape={CustomCell}
          data={data}
          defs={[
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: 'rgba(0, 0, 0, 0.1)',
              rotation: -45,
              lineWidth: 4,
              spacing: 7
            }
          ]}
          height={1790}
          hoverTarget='cell'
          margin={{ top: 0, right: 5, bottom: 20, left: 30 }}
          maxValue={maxValue}
          motionDamping={9}
          motionStiffness={80}
          theme={commonTheme}
          tooltip={({ xKey, yKey, value }) => (
            <>
              <div>
                {StatesEnum[yKey]} , {xKey}
              </div>
              {value === '' ? (
                <div>0 policy items</div>
              ) : (
                <div>
                  {value} {value === 1 ? 'policy item' : 'policy items'}
                </div>
              )}
            </>
          )}
        />
      </div>
    </>
  )
}

Heatmap.propTypes = {
  /**
   * List of data to show in the heatmap
   */
  data: PropTypes.array,
  maxValue: PropTypes.number
}

Heatmap.defaultProps = {
  data: [],
  maxValue: 0
}
