import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Grid, makeStyles } from '@material-ui/core'
import { uniqBy, size, isEmpty, omit } from 'lodash'

import { Card, DataUnavailable, LoadingSpinner, ErrorMessage, KPI } from '@/components'
import { nationalBillProgressQuery } from '@/graphql/national'
import { useSearchParams } from '@/hooks'
import { BillStatusEnum } from '@/utils/constants'

import { DotPlotKpi, PolicyDotPlot } from './components'

const useStyles = makeStyles({
  chartTitle: {
    color: '#fff',
    letterSpacing: '0.2em',
    marginRight: '.8rem'
  },
  chartMenuContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  }
})

/**
 * Policy Progress Card
 * Showcasing actions taken across policy items
 * along with a Policy Dot plots info-graphic.
 * @component
 */
export default function PolicyProgress() {
  const classes = useStyles()

  const [historyData, setHistoryData] = useState(null)
  const [downloadData, setDownloadData] = useState([])
  const [isDataEmpty, setIsDataEmpty] = useState(false)

  const [session, setSession] = useState('')
  const [totalPolicyItems, setTotalPolicyItems] = useState(null)
  const [avgAction, setAvgAction] = useState(null)
  const [totalActions, setTotalActions] = useState(null)

  const { tags, startDate, endDate } = useSearchParams()

  const { loading, error } = useQuery(nationalBillProgressQuery, {
    variables: {
      tags,
      startDate,
      endDate
    },
    onCompleted: ({ nationalBillProgress }) => {
      const hData = nationalBillProgress.historyData
      const totalA = hData.reduce((total, obj) => total + obj.actionCount, 0)
      const totalPI = size(uniqBy(hData, 'billNumber').map((a) => a.billNumber))
      const avgA = totalPI === 0 ? 0 : Math.round(totalA / totalPI)

      setIsDataEmpty(isEmpty(hData))

      setTotalActions(totalA)
      setTotalPolicyItems(totalPI)
      setAvgAction(avgA)
      setSession(nationalBillProgress.session)
      setHistoryData(hData)

      const csvData = hData.map((x) => {
        const a = omit(x, ['status'])
        return {
          status: BillStatusEnum[x.status],
          ...a
        }
      })

      setDownloadData([...csvData])
    }
  })

  const infoData = [
    {
      title: 'How far has legislation moved through Congress?',
      description: [
        'This chart shows a timeline of legislative items for the most recent congressional session based on the dates you chose. The timeline starts with the most recent legislative item. Each row represents a unique legislative item. Each circle contains the number of actions taken on the legislative item during that month. Hover over a row to see the name of the legislative item, the total number of actions, and the date and description of the last action.'
      ],
      dimension: ['[X-Axis] Month within the congressional session', '[Y-Axis] Designation of the legislative item'],
      note: null
    }
  ]

  return (
    <Card
      downloadData={downloadData}
      downloadDisabled={error || loading || isDataEmpty}
      fileName='PolicyProgress'
      infoData={infoData}
    >
      {error && <ErrorMessage />}
      {loading && <LoadingSpinner />}
      {isDataEmpty && <DataUnavailable />}
      {historyData && !isDataEmpty && (
        <>
          <KPI>
            <DotPlotKpi
              totalActions={totalActions}
              totalPolicyItems={totalPolicyItems}
              session={session}
              avgAction={avgAction}
            />
          </KPI>
          <Grid container item style={{ flex: '1 1 65%', padding: '20px 10px 20px 30px' }}>
            <div className={classes.chartMenuContainer}>
              <span className={classes.chartTitle}>LEGISLATIVE ITEMS OVER TIME</span>
            </div>
            {totalActions > 0 ? (
              <PolicyDotPlot historyData={historyData} totalPolicyItems={totalPolicyItems} />
            ) : (
              <DataUnavailable />
            )}
          </Grid>
        </>
      )}
    </Card>
  )
}
