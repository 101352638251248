import { faMapMarkedAlt, faScroll } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tabs, Tab, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useBasePath, useSelectedTab } from '@/hooks'

const useStyles = makeStyles(() => ({
  labelIcon: {
    color: 'white',
    fontSize: '1rem',
    fontWeight: 300,
    letterSpacing: '0.2em'
  },
  tabSelected: {
    color: 'white',
    fontWeight: 500,
    letterSpacing: '0.2em',
    fontSize: '1rem'
  }
}))

const MasterButtonBar = () => {
  const [selectedTab, updateTab] = useSelectedTab()
  const base = useBasePath()

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  const classes = useStyles()

  const handleChange = (event, newValue) => updateTab(newValue)

  return (
    <Tabs
      aria-label='master button tabs'
      centered
      id='masterBtnBar'
      indicatorColor='primary'
      onChange={handleChange}
      textColor='primary'
      value={selectedTab}
      variant='fullWidth'
    >
      <Tab
        classes={{
          labelIcon: classes.labelIcon,
          selected: classes.tabSelected
        }}
        icon={<FontAwesomeIcon icon={faScroll} />}
        component={Link}
        to={`${base}/national`}
        label='NATIONAL LEGISLATION'
        aria-label='National Legislation'
        {...a11yProps(1)}
      />
      <Tab
        classes={{
          labelIcon: classes.labelIcon,
          selected: classes.tabSelected
        }}
        icon={<FontAwesomeIcon icon={faMapMarkedAlt} />}
        component={Link}
        to={`${base}/state`}
        label='STATE LEGISLATION'
        aria-label='State Legislation'
        {...a11yProps(2)}
      />
    </Tabs>
  )
}

export default MasterButtonBar
