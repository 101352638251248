import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

const useStyles = makeStyles((theme) => ({
  noTopicsRoot: {
    textAlign: 'center',
    color: 'white'
  },
  toggleGroup: {
    backgroundColor: 'transparent',
    position: 'relative'
  },
  toggleButtonLabel: {
    color: 'white',
    textTransform: 'none',
    fontSize: '0.9rem'
  },
  toggleButton: {
    cursor: 'default',
    borderRadius: 7,
    border: 1,
    borderWidth: 1,
    borderStyle: 'solid',
    margin: 5,
    width: 'auto',
    color: 'rgb(243, 114, 44)',
    padding: '0 0.5em 0 0.5em',
    height: '2rem'
  },
  toggleContainer: {
    margin: theme.spacing(15, 0)
  },
  topicToggleGroup: {
    width: '90vw',
    margin: 'auto',
    cursor: 'default'
  }
}))

/**
 * Used for data tables and showing topic chips
 */
export default function TopicsLayout({ data }) {
  const classes = useStyles()

  return data.length !== 0 ? (
    <>
      <div className={classes.topicToggleGroup}>
        {data.map((button) => (
          <ToggleButtonGroup key={button} exclusive className={classes.toggleGroup} disabled>
            <ToggleButton
              classes={{
                root: classes.toggleButton,
                selected: classes.toggleButtonSelected,
                label: classes.toggleButtonLabel
              }}
              key={button}
              value={button}
              disableRipple
            >
              {button}
            </ToggleButton>
          </ToggleButtonGroup>
        ))}
      </div>
    </>
  ) : (
    <div className={classes.noTopicsRoot}>
      <Typography className={classes.noTopicsRoot}>No legislative subjects available.</Typography>
    </div>
  )
}
