import { useState, useEffect } from 'react'
import { filter, size, chain, mergeWith, min, max, omit } from 'lodash'

import { useQuery } from '@apollo/client'
import { Grid, makeStyles } from '@material-ui/core'

import StateKpiHeatMap from './components/StateKpiHeatMap'
import { Card, Heatmap, SingleSelect, LoadingSpinner, ErrorMessage, KPI } from '@/components'
import { statePolicyHeatMapQuery } from '@/graphql/state/'
import { useSearchParams } from '@/hooks'
import { StateHeatMapYears, stateInfoContentData, typeOptions } from '@/utils/constants'
import { formatHeatMapChart } from '@/utils/formatNivo'
import { getTotal, billSwitch } from '@/utils/helper'

const useStyles = makeStyles(() => ({
  chartTitle: {
    color: '#fff',
    letterSpacing: '0.2em',
    marginRight: '.8rem'
  },
  chartMenuContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    paddingBottom: 20
  },
  filterGroup: {
    flexGrow: 1,
    width: '60%',
    margin: '0 1rem 0 0'
  }
}))

/**
 * Policy By State Card
 * Show casing policy over time per state over
 * the past 10 years related to the searched term.
 * @component
 */
export default function PolicyByState() {
  const classes = useStyles()

  // State Variable for Query
  const [queryData, setQueryData] = useState(null)

  // State Variables based on agreggating data
  const [maxValue, setMaxValue] = useState(null)
  const [formattedData, setFormattedData] = useState(null)
  const [downloadData, setDownloadData] = useState(null)

  // Count and Total State Variables
  const [stateCount, setStateCount] = useState(null)
  const [totalPolicyItems, setTotalPolicyItems] = useState(null)

  // State Variables based on Agreggating Data across all years.
  const [topStateThisYear, setTopStateThisYear] = useState(null)
  const [topStateAcrossYears, setTopStateAcrossYears] = useState(null)

  // Default Initialized State Variables
  const [hasLoaded, setHasLoaded] = useState(false)
  const [filterState, setFilterState] = useState({ selectedType: 'ALL' })

  const infoData = filter(stateInfoContentData, (o) => o.title === 'How does state legislation trend over time?')

  // Variables for useQuery
  const { tags } = useSearchParams()

  /**
   * Function that maps data to be donwloaded as csv
   * @function
   * @param {Object[]} data - Array of objects to be downloaded in a CSV format
   */
  const downloadCSV = (data) =>
    data.map((x) => {
      const a = omit(x, ['billType'])
      const billType = billSwitch(x.billType)

      return {
        billType: billType,
        ...a
      }
    })

  /**
   * Reducer function on the array of objects based on Year and
   * State. If key does not exist already; creates a new object
   * with a count prop set to 0 then increments it.
   * @param {Object[]} dataArray - Arr of Obj. representing state heat map results.
   * @returns {Object[]} -  Arr of Obj. representing state heat map results.
   */
  function aggData(dataArray) {
    const agg = [
      ...dataArray
        .reduce((r, o) => {
          const key = `${o.state}-${o.year}`
          const item = r.get(key) || { ...o, count: 0 }
          item.count += o.count
          return r.set(key, item)
        }, new Map())
        .values()
    ]
    return agg
  }

  const { loading, error } = useQuery(statePolicyHeatMapQuery, {
    variables: {
      tags
    },
    onCompleted: ({ statePolicyHeatMap: data }) => {
      setQueryData(data)

      const aggregatedData = aggData(data)
      const aggAllYears = chain(data)
        .groupBy('state')
        .map((obj) =>
          mergeWith.apply(
            obj,
            [{}].concat(obj, (obj, src, key) => (key === 'count' ? (obj || 0) + src : undefined))
          )
        )
        .value()
      const mapped = aggregatedData.map((item) => item.count)

      const curYear = new Date().getFullYear()
      const dataThisYear = aggregatedData.filter((x) => x.year === curYear)
      const topCount = Math.max(...dataThisYear.map((o) => o.count))
      const topState = dataThisYear.filter((item) => item.count === topCount)

      const sCount = size([...new Set(data.map((item) => item.state))])
      const total = getTotal(data)

      const tpc = Math.max(...aggAllYears.map((o) => o.count))
      const topStateYears = aggAllYears.filter((item) => item.count === tpc)

      setStateCount(sCount)

      setTopStateAcrossYears(topStateYears)
      setTopStateThisYear(topState)

      setTotalPolicyItems(total)
      setMaxValue((min(mapped) + max(mapped)) / 2)
      setFormattedData(formatHeatMapChart(aggregatedData))

      const csvData = downloadCSV(aggregatedData)

      setDownloadData([...csvData])

      setHasLoaded(sCount && topStateYears && topState && mapped)
    }
  })

  useEffect(() => {
    if (queryData) {
      const { selectedType: type } = filterState

      const f = filter(queryData, (o) => {
        if (type === 'ALL') return queryData
        else if (type === 'B') return o.billType === 'B'
        else return o.billType === 'R' || o.billType === 'JR' || o.billType === 'CR'
      })

      const agg = aggData(f)
      const mapped = agg.map((item) => item.count)

      const csvData = downloadCSV(agg)
      setDownloadData([...csvData])
      setMaxValue((min(mapped) + max(mapped)) / 2)
      setFormattedData(formatHeatMapChart(agg))
    }
  }, [filterState])

  const handleChange = (e) => {
    setFilterState({
      ...filterState,
      [e.target.name]: e.target.value
    })
  }

  return (
    <Card downloadData={downloadData} downloadDisabled={error || loading} fileName='PolicyByState' infoData={infoData}>
      {error && <ErrorMessage />}
      {loading && <LoadingSpinner />}
      {hasLoaded && (
        <>
          <KPI>
            <StateKpiHeatMap
              policyCount={totalPolicyItems}
              totalYears={StateHeatMapYears.length}
              stateCount={stateCount}
              topStateAllyears={topStateAcrossYears}
              topStateThisYear={topStateThisYear}
            />
          </KPI>
          <Grid container item style={{ flex: '1 1 65%', padding: '20px 10px 20px 30px' }}>
            <div className={classes.chartMenuContainer}>
              <span className={classes.chartTitle}>LEGISLATIVE ITEMS OVER TIME</span>
              <div className={classes.filterGroup}>
                <SingleSelect
                  ariaLabel='Select type of policy'
                  name='selectedType'
                  options={typeOptions}
                  onChange={handleChange}
                  value={filterState.selectedType}
                />
              </div>
            </div>
            <Heatmap data={formattedData} maxValue={maxValue} />
          </Grid>
        </>
      )}
    </Card>
  )
}
