import { isMobile } from 'react-device-detect'
import SearchLayout from './Search/Search'
import './Home.css'
import { ErrorBanner, Tutorial, MobilePopup } from '@/components'

const HomeLayout = () => (
  <div className='home'>
    {isMobile ? <MobilePopup /> : <Tutorial isHomeTut={true} />}
    <SearchLayout />
    <ErrorBanner />
  </div>
)

export default HomeLayout
