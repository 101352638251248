import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import PropTypes from 'prop-types'
import Pluralize from 'react-pluralize'
import { countBy, entries, flow, head, last, maxBy, partialRight } from 'lodash'
import { useSearchParams } from '@/hooks'
import { BodyToggle, DataUnavailable } from '@/components'
import { getTotal, getFormattedNumber, searchedTags } from '@/utils/helper'
import { BillStatusEnum } from '@/utils/constants'

const useStyles = makeStyles((theme) => ({
  statistic: theme.kpiStatistic,
  kpiTitle: theme.kpiTitle,
  kpiDescription: theme.kpidescription,
  subNumbers: {
    fontSize: '2.8rem',
    lineHeight: 1
  },
  subNames: {
    fontSize: '.8rem'
  }
}))

/**
 * Shows the breakdown of members of congress supporting bills within the selected range.
 * @component
 */
export default function SupportersKpi({ congressBillStatus, congressCount, resetSelected }) {
  const classes = useStyles()
  let statusPercent = ''
  let maxStatus = ''
  const totalCount = getTotal(congressBillStatus)
  const averageSupportedItems = Math.round(totalCount / congressCount.total)

  const statusPercentage = () => {
    maxStatus = flow(countBy, entries, partialRight(maxBy, last), head)(congressBillStatus.map((t) => t.status))
    const filteredData =
      congressBillStatus && congressBillStatus.filter((item) => item.status && item.status.includes(maxStatus))

    const statusPercent =
      filteredData && filteredData.length > 0 ? `${Math.round((filteredData[0].count / totalCount) * 100)}% ` : 'N/A'
    return statusPercent
  }

  statusPercent = statusPercentage()

  const searchParams = useSearchParams()
  const { startDate, endDate, tags } = searchParams
  const tagNames = Object.keys(tags)

  const searchedTerms = searchedTags(tagNames)
  const statusDescription =
    maxStatus !== '' ? BillStatusEnum[maxStatus].charAt(0).toLowerCase() + BillStatusEnum[maxStatus].slice(1) : ''

  return congressCount.total === 0 ? (
    <DataUnavailable />
  ) : (
    <>
      <Grid container style={{ flexWrap: 'nowrap' }}>
        <Grid container direction='column' alignItems='center' justifyContent='center' item>
          <Typography className={classes.statistic}>{getFormattedNumber(congressCount.total, 0, 1)}</Typography>
          <Typography variant='h5' display='block' className={classes.kpiTitle} align='center'>
            TOTAL MEMBERS OF CONGRESS
          </Typography>
        </Grid>
        <hr style={{ backgroundColor: 'white', width: '3px' }} />
        <Grid
          item
          container
          direction='column'
          alignItems='flex-start'
          justifyContent='center'
          style={{ paddingLeft: 20 }}
        >
          <Grid item>
            <Typography className={classes.subNumbers}>{getFormattedNumber(congressCount.rep, 0, 1)}</Typography>
            <Typography variant='h6' className={classes.subNames}>
              REPRESENTATIVES
            </Typography>
          </Grid>
          <hr style={{ backgroundColor: 'white', height: 1, width: '60%', margin: '10px 0' }} />
          <Grid item>
            <Typography className={classes.subNumbers}>{getFormattedNumber(congressCount.sen, 0, 1)}</Typography>
            <Typography variant='h6' className={classes.subNames}>
              SENATORS
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Typography align='center' className={classes.kpiDescription} style={{ paddingTop: 15 }}>
        {congressCount.total} {congressCount.total > 1 ? 'member' : 'member'} of Congress sponsored legislation related
        to <i>{searchedTerms}</i> from {moment.utc(startDate).format('MMMM D, YYYY')} to{' '}
        {moment.utc(endDate).format('MMMM D, YYYY')}. On average, featured congress members have supported{' '}
        <Pluralize singular='item' count={averageSupportedItems} />. Across all items, {statusPercent} have a status of{' '}
        {statusDescription}.
      </Typography>

      <BodyToggle resetSelected={resetSelected} />
    </>
  )
}

SupportersKpi.propTypes = {
  /**
   * Array of all bills and statuses
   */
  congressBillStatus: PropTypes.array,
  /**
   * numbers of congress supporting the bills
   */
  congressCount: PropTypes.shape({
    /**
     * total number of members
     */
    total: PropTypes.number,
    /**
     * number of representatives
     */
    rep: PropTypes.number,
    /**
     * number of senators
     */
    sen: PropTypes.number
  }),
  /**
   * handle resetting when the user changes the chamber toggle
   */
  resetSelected: PropTypes.func
}

SupportersKpi.defaultProps = {
  congressBillStatus: [],
  congressCount: {
    total: 0,
    rep: 0,
    sen: 0
  },
  resetSelected: null
}
