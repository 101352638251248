import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useSearchParams } from '@/hooks'
import { searchedTags, splitTopState, getFormattedNumber } from '@/utils/helper'
import { BillStatusEnum } from '@/utils/constants'

const useStyles = makeStyles((theme) => ({
  mapKpiTitle: theme.kpiTitle,
  mapKpiContainer: {
    top: '50%',
    transform: 'translateY(-50%)',
    position: 'relative',
    margin: 'auto auto auto 1.2em'
  },
  dataStory: theme.kpidescription,
  Kpi: theme.kpiStatistic,
  topState: {
    paddingTop: '1rem'
  }
}))

export default function StateKpiMap({ stateCount, policyCount, topState, topStatus, topStatePolicyCount }) {
  const classes = useStyles()
  const searchParams = useSearchParams()
  const tagNames = Object.keys(searchParams.tags)
  const searchedTerms = searchedTags(tagNames)

  const topStateNames = splitTopState(topState)
  const statusDescription = BillStatusEnum[topStatus].charAt(0).toLowerCase() + BillStatusEnum[topStatus].slice(1)
  return (
    <>
      <Typography className={classes.Kpi} variant='h1'>
        {stateCount}
      </Typography>
      <Typography className={classes.mapKpiTitle} align='center'>
        {stateCount === 1 ? 'STATE AND TERRITORY' : 'STATES AND TERRITORIES'}
      </Typography>
      <Typography className={classes.dataStory}>
        {stateCount} {stateCount === 1 ? 'state' : 'states'} {stateCount === 1 ? 'has' : 'have'}{' '}
        {getFormattedNumber(policyCount, 0, 1)} {policyCount === 1 ? 'legislative item' : 'legislative items'}{' '}
        associated with <i>{searchedTerms}</i>. Most items have a status of {statusDescription}.<br></br>
        <br></br>
        {topStateNames} {topState.length === 1 ? 'has' : 'have'} the most legislation with{' '}
        {getFormattedNumber(topStatePolicyCount, 0, 1)} legislative {topStatePolicyCount === 1 ? 'item.' : 'items.'}
      </Typography>

      <Typography className={classes.dataStory}></Typography>
    </>
  )
}
