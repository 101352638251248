import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { ErrorMessage, ErrorBoundary, HeaderCDC, Footer } from '@/components'
import { useSearchParams } from '@/hooks'

import { MasterButtonBar, SearchResultsHeader } from './components'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  container: {
    flexGrow: 1,
    height: '100%'
  },
  tabsContainer: {
    margin: '2em 0 1em'
  }
})

/**
 * The layout used for showing search results, either for state or national
 * @component
 */
export default function IssueMonitorLayout({ children }) {
  const classes = useStyles()
  const searchParams = useSearchParams()
  const { startDate, endDate } = searchParams
  const hasDateParams = () => startDate.length > 0 && endDate.length > 0

  return (
    <>
      <HeaderCDC />
      <ErrorBoundary>
        <div className={classes.root}>
          {!hasDateParams() && <ErrorMessage />}
          {hasDateParams() && (
            <Container className={classes.container} maxWidth={false}>
              <div className={classes.cardContainer}>
                <SearchResultsHeader searchParams={searchParams} />
                <div className={classes.tabsContainer}>
                  <MasterButtonBar />
                </div>
                {children}
              </div>
            </Container>
          )}
        </div>
        <Footer />
      </ErrorBoundary>
    </>
  )
}
