import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import { maxBy, chain, mergeWith } from 'lodash'
import Pluralize from 'react-pluralize'

import { DataUnavailable } from '@/components'
import { BillStatusEnum } from '@/utils/constants'
import { getFormattedNumber } from '@/utils/helper'

const useStyles = makeStyles((theme) => ({
  statistic: theme.kpiStatistic,
  subtitle: theme.kpiTitle,
  dataStory: theme.kpidescription
}))

export default function TopicsKpi({ data, policyTotal, topicCount }) {
  if (data.length === 0) {
    return <DataUnavailable />
  }

  const classes = useStyles()
  const topTopic = data.length > 0 ? maxBy(data, 'statusCount') : {}

  const { subjectName } = topTopic

  const aggregateStatus = chain(data)
    .groupBy('status')
    .map((obj) =>
      mergeWith.apply(
        obj,
        [{}].concat(obj, (obj, src, key) => (key === 'statusCount' ? (obj || 0) + src : undefined))
      )
    )
    .value()

  const topStatus = aggregateStatus.length > 0 ? maxBy(aggregateStatus, 'statusCount') : {}
  const { status } = topStatus
  const statusDescription =
    data.length > 0 ? BillStatusEnum[status].charAt(0).toLowerCase() + BillStatusEnum[status].slice(1) : ''

  return (
    <>
      <Typography className={classes.statistic} variant='h1'>
        {getFormattedNumber(topicCount, 0, 1)}
      </Typography>
      <Typography className={classes.subtitle} variant='h1' align='center'>
        SUBJECTS REPRESENTED
      </Typography>
      <Typography className={classes.dataStory}>
        <Pluralize singular='subject' count={topicCount} /> subjects were represented across{' '}
        {getFormattedNumber(policyTotal, 0, 1)} legislative {policyTotal === 1 ? 'item.' : 'items.'} {subjectName} was
        the most common subject, while {statusDescription} was the most common status.
      </Typography>
    </>
  )
}
