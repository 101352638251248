import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'

import { useMapFeedStatus } from '@/hooks'
import { Tutorial, MobilePopup } from '@/components'
import {
  NationalKPI,
  PolicyOverTime,
  NationalTable,
  TopicsByStatus,
  TopSupporters,
  AdvocateMap,
  PolicyProgress
} from './Cards'

/**
 * National Dashboard Card
 * Parent Component containing all Cards related to
 * National Policy
 * @component
 */

export default function NationalDashboard() {
  const [, updateMapFeedStatus] = useMapFeedStatus()

  useEffect(() => {
    updateMapFeedStatus(false)
  }, [])

  return (
    <div id='National Dashboard '>
      {isMobile ? (
        <MobilePopup />
      ) : (
        <>
          <Tutorial isHomeTut={false} />
          <NationalKPI />
          <NationalTable />
          <PolicyOverTime />
          <TopicsByStatus />
          <PolicyProgress />
          <TopSupporters />
          <AdvocateMap />
        </>
      )}
    </div>
  )
}
