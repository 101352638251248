import { useQuery } from '@apollo/client'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { maxBy, filter, isEmpty } from 'lodash'
import { useState } from 'react'

import { Card, LoadingSpinner, DataUnavailable, ErrorMessage, KPI, PolicyMap } from '@/components'
import { advocateMapQuery } from '@/graphql/national'
import { useSearchParams } from '@/hooks'
import { natInfoContentData, FeedType } from '@/utils/constants'
import { KpiMap } from './components'

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    paddingRight: '10px'
  },
  Kpi: {
    color: '#FFFFFF',
    fontSize: '4rem',
    textAlign: 'center'
  },
  mapKpiTitle: theme.kpiTitle,
  mapKpiContainer: {
    verticalAlign: 'center',
    paddingBottom: '0.9em',
    margin: 'auto auto auto 1.2em'
  },
  kpiDivider: {
    backgroundColor: '#FFFFFF'
  },
  dataStory: theme.kpidescription,
  drawerPaper: {
    width: 240,
    position: 'relative'
  },
  chartTitle: {
    color: '#fff',
    paddingBottom: '1rem',
    letterSpacing: '0.2em'
  },
  chartMenuContainer: {
    display: 'flex'
  }
}))

/**
 * Advocate Map Card
 * Show casing Represented States and Territories
 * sponsoriing legislative items on the search term.
 * @component
 */
export default function AdvocateMap() {
  const [queryData, setQueryData] = useState(null)
  const [billMapData, setBillMapData] = useState(null)
  const [downloadData, setDownloadData] = useState([])

  const [billFeed, setBillFeed] = useState(null)

  const [stateBillCount, setStateBillCount] = useState(null)
  const [congressBillCount, setCongressBillCount] = useState(null)
  const [topStateBills, setTopStateBills] = useState(null)

  const [isDataEmpty, setIsDataEmpty] = useState(false)

  const { tags, startDate, endDate } = useSearchParams()

  const { loading, error } = useQuery(advocateMapQuery, {
    variables: {
      tags,
      startDate,
      endDate
    },
    onCompleted: (data) => {
      const isDEmpty =
        isEmpty(data.topLevelBillKpisData) || isEmpty(data.billMapData) || isEmpty(data.billFeedWithState)
      setIsDataEmpty(isDEmpty)
      setQueryData(data)
      setBillMapData(data.billMapData)
      setBillFeed(data.billFeedWithState)
      setStateBillCount(data.topLevelBillKpisData.stateBillCount)
      setCongressBillCount(data.topLevelBillKpisData.congressBillCount)
      setTopStateBills(maxBy(data.billMapData, 'count'))
      setDownloadData([...data.billFeedWithState])
    }
  })

  const infoData = [
    {
      title: 'Where are the legislative item sponsors from?',
      description: [
        'This map shows the number of representatives and senators sponsoring legislative items that match your search choices. Click a state or Washington DC to see a list of legislative items sponsored by representatives of that state. You may need to click the minus sign above the map to zoom out to see Alaska and Hawaii.'
      ],
      dimension: [
        '[Color] Corresponds to the number of representatives from a state sponsoring a legislative item that matches your search choices.'
      ],
      note: null
    }
  ]

  const classes = useStyles()

  return (
    <Card
      downloadDisabled={error || loading || isDataEmpty}
      downloadData={downloadData}
      fileName='AdvocateMap'
      infoData={infoData}
    >
      {error && <ErrorMessage />}
      {loading && <LoadingSpinner />}
      {isDataEmpty && <DataUnavailable />}
      {queryData && !isDataEmpty && topStateBills && (
        <>
          <KPI>
            <KpiMap stateCount={stateBillCount} congressCount={congressBillCount} topState={topStateBills} />
          </KPI>
          <Grid style={{ flex: '1 1 65%', padding: 20 }}>
            <div className={classes.chartMenuContainer}>
              <span className={classes.chartTitle}>CONGRESSMEMBERS BY STATE</span>
            </div>
            <div className={classes.mapContainer}>
              <PolicyMap
                counts={billMapData}
                countType='Members'
                feed={billFeed}
                feedType={FeedType.NATIONAL_BILL}
                title='MEMBER ITEMS'
              />
            </div>
          </Grid>
        </>
      )}
    </Card>
  )
}
