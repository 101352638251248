import Pluralize from 'react-pluralize'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from '@material-ui/core'
import { getFormattedNumber } from '@/utils/helper'
import useStyles from '@/style/KPIStyle'

/**
 * Statistic KPI component that is used in almost all cards
 * through out the app. Show cases the statistic in a title
 * format / style.
 * @component
 */
export default function StatisticKPI({ title, icon, statistic }) {
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.statistic} variant='h1'>
        <FontAwesomeIcon icon={icon} color='white' /> {getFormattedNumber(statistic, 0, 1)}
      </Typography>
      <Typography className={classes.subtitle1} variant='h1' align='center'>
        <Pluralize singular={title} count={statistic} showCount={false} />
      </Typography>
    </>
  )
}
