import { IconButton, Tooltip } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { useResultsPageTutorial, useHomepageTutorial } from '@/hooks'
import PropTypes from 'prop-types'

/**
 * Shows a button for tutorial download.
 * @component
 */
export default function HelpButton({ localStorageText, isHomeTut }) {
  const [, updateResultPageTutorial] = useResultsPageTutorial()
  const [, updateHomepageTutorial] = useHomepageTutorial()

  /**
   * Called when user clicks button. Removes hide tutorial item from local
   * storage if it exits, then turns on the tutorial.
   * @return {void}
   */
  const handleClick = () => {
    const hidden = localStorage.getItem(localStorageText)

    if (hidden) {
      localStorage.removeItem(localStorageText)
    }
    isHomeTut ? updateHomepageTutorial(true) : updateResultPageTutorial(true)
  }

  return (
    <Tooltip title='Start Tutorial'>
      <IconButton aria-label='download Data' onClick={handleClick} style={{ color: 'white' }}>
        <HelpOutlineIcon />
      </IconButton>
    </Tooltip>
  )
}

HelpButton.propTypes = {
  /**
   * Determines which global state the help icon should update
   */
  isHomeTut: PropTypes.bool.isRequired,
  /**
   * Name of local storage object to be removed
   */
  localStorageText: PropTypes.string.isRequired
}
