import { InMemoryCache, makeVar } from '@apollo/client'

// app status -- is there any error?
export const appStatusVar = makeVar({ message: null, hasError: false })

// gql error
export const gqlErrorVar = makeVar({ message: null, hasError: false })

// start and end dates
export const startDateVar = makeVar('')
export const endDateVar = makeVar('')

// tag var
export const currentTagsVar = makeVar('')

// house toggle
export const senateHouseVar = makeVar('rep')

// kpi body
export const selectedKPIbodyVar = makeVar('Representative')

// sort toggle
export const sortToggleVar = makeVar('createdAt')

// map feed
export const isMapFeedOpenVar = makeVar(false)

// home tutorial
export const homepageTutorialVar = makeVar(true)

// tab tutorial
export const resultsPageTutorialVar = makeVar(true)
/**
 * This represents LOCAL-only variables that are shared
 * amongst pages
 */
export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        appStatus: {
          read() {
            return appStatusVar()
          }
        },
        gqlError: {
          read() {
            return gqlErrorVar()
          }
        },
        startDate: {
          read() {
            return startDateVar()
          }
        },
        endDate: {
          read() {
            return endDateVar()
          }
        },
        tags: {
          read() {
            return currentTagsVar()
          }
        },
        senateHouseToggle: {
          read() {
            return senateHouseVar()
          }
        },
        selectedKPIbody: {
          read() {
            return selectedKPIbodyVar()
          }
        },
        sortToggle: {
          read() {
            return sortToggleVar()
          }
        },
        isMapFeedOpen: {
          read() {
            return isMapFeedOpenVar()
          }
        },
        homepageTutorialToggle: {
          read() {
            return homepageTutorialVar()
          }
        },
        resultsPageTutorialToggle: {
          read() {
            return resultsPageTutorialVar()
          }
        }
      }
    }
  }
})
