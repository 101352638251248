import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Checkbox } from '@material-ui/core'
import Joyride, { ACTIONS, EVENTS } from 'react-joyride'
import { useResultsPageTutorial, useHomepageTutorial } from '@/hooks'
import { HIDE_HOMEPAGE_TUTORIAL, HIDE_RESULTS_PAGE_TUTORIAL } from 'src/utils/constants'

/**
 * Dashboard Tutorial
 * Component that handles the tutorial / onboarding
 * on National & State Dashboard screen.
 * @component
 */
export default function Tutorial({ isHomeTut }) {
  const [stepIndex, setStepIndex] = useState(0)
  const [checked, setChecked] = useState(false)

  const [resultsPageOpen, setDashboardToggle] = useResultsPageTutorial()
  const [homePageOpen, setHomepageTutorial] = useHomepageTutorial()

  const LOCAL_STORAGE_ITEM = isHomeTut ? HIDE_HOMEPAGE_TUTORIAL : HIDE_RESULTS_PAGE_TUTORIAL

  const TutorialText = ({ children }) => <p style={{ textAlign: 'left' }}>{children}</p>

  useEffect(() => {
    const hidden = localStorage.getItem(LOCAL_STORAGE_ITEM)
    if (hidden) {
      setChecked(true)
      isHomeTut ? setHomepageTutorial(false) : setDashboardToggle(false)
    }
  }, [resultsPageOpen, homePageOpen])

  /**
   * Sets localstorage with item that prevents tut from opening in future
   * @param {object} e - Event object
   * @return {void}
   */
  const handleCheckboxClick = (e) => {
    const state = e.target.checked
    setChecked(state)
    if (state) localStorage.setItem(LOCAL_STORAGE_ITEM, state)
    if (!state) localStorage.removeItem(LOCAL_STORAGE_ITEM)
  }

  /**
   * Function to be called when Joyride's state changes.
   * @param {object} data - Data object to be passed containing state info.
   * @return {void}
   */
  const handleJoyrideCallback = (data) => {
    const { type, action, index } = data

    if (action === ACTIONS.CLOSE || type === EVENTS.TOUR_END) {
      setStepIndex(0)
      isHomeTut ? setHomepageTutorial(false) : setDashboardToggle(false)
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const step = index + (action === ACTIONS.PREV ? -1 : 1)
      const maxIndexLength = isHomeTut ? HOMEPAGE_STEPS.length : RESULTS_STEPS.length

      const nextActionCheck = index >= 0 && index < maxIndexLength ? true : false
      const prevActionCheck = index >= 1 && index < maxIndexLength + 1 ? true : false

      // Update state to advance the tour
      if (nextActionCheck && action === ACTIONS.NEXT) setStepIndex(step)
      // Going back a step on tour
      else if (prevActionCheck && action === ACTIONS.PREV) setStepIndex(step)
    }
  }

  const checkBoxComponent = () => (
    <span style={{ left: 15, bottom: 20, position: 'absolute', fontSize: '.7rem' }}>
      <Checkbox
        onChange={(e) => handleCheckboxClick(e)}
        style={{ padding: 0, bottom: '1px', marginRight: '2px' }}
        checked={checked}
        size='small'
        color='primary'
        inputProps={{ 'aria-label': `Hide tutorial` }}
      />
      Don&apos;t show this tutorial again.
    </span>
  )

  const RESULTS_STEPS = [
    {
      target: '#searchedItems',
      disableOverlayClose: true,
      title: 'Search Details',
      content: (
        <>
          <TutorialText>
            Text at the top of the page lists the search choices you selected on the previous page.
          </TutorialText>

          {checkBoxComponent()}
        </>
      ),
      disableBeacon: true
    },
    {
      target: '#dataDictionary',
      disableOverlayClose: true,
      title: 'Glossary',
      content: (
        <>
          <TutorialText>
            Click the <b>book</b> icon to open a glossary of terms used in this application.
          </TutorialText>

          {checkBoxComponent()}
        </>
      )
    },
    {
      target: '#backToSearch',
      disableOverlayClose: true,
      title: 'Back Button',
      content: (
        <>
          <TutorialText>
            Click the <b>arrow</b> button to return to the home page, where you can change the filters and date range
            you selected.
          </TutorialText>

          {checkBoxComponent()}
        </>
      )
    },
    {
      target: '#masterBtnBar',
      disableOverlayClose: true,
      title: 'National Legislation and State Legislation Tabs',
      content: (
        <>
          <TutorialText>
            The <b>National Legislation</b> tab shows legislation at the federal level from Congress. The{' '}
            <b>State Legislation</b> tab shows legislation in individual states and Washington DC.
          </TutorialText>

          {checkBoxComponent()}
        </>
      )
    },
    {
      target: '#buttonGroupDiv',
      disableOverlayClose: true,
      title: 'Download Data and Data Information Icons',
      content: (
        <>
          <TutorialText>
            Click the <b>i</b> icon to see information about the graph, visualization, or table directly below. Click
            the <b>cloud</b> icon to download the data used to create the graph, visualization, or table.
          </TutorialText>

          {checkBoxComponent()}
        </>
      )
    }
  ]

  const HOMEPAGE_STEPS = [
    {
      target: '#root',
      disableOverlayClose: true,
      title: 'Welcome to the Issue and Policy Scanner',
      content: (
        <>
          <TutorialText>
            Hello! This tutorial shows you briefly how the Scanner works. Click the <b>X</b> in the upper right corner
            to exit the tutorial at any time. Click the <b>NEXT</b> button to start.
          </TutorialText>

          {checkBoxComponent()}
        </>
      ),
      placement: 'center'
    },
    {
      target: '#filterButtons',
      disableOverlayClose: true,
      title: 'Filters',
      content: (
        <>
          <TutorialText>
            Use these buttons to narrow down your search results to only the cancer types and topics you want. The
            filters are grouped into three categories: cancer type, screening and survivors, and social determinants of
            health. You can select one filter from each category.
          </TutorialText>

          {checkBoxComponent()}
        </>
      )
    },
    {
      target: '#date-pickers',
      disableOverlayClose: true,
      title: 'Start Date and End Date',
      content: (
        <>
          <TutorialText>
            You can set a time period to search by changing the start date and/or end date. You can type in the date or
            click the <b>calendar</b> icon in each field to open a date picker.
          </TutorialText>

          {checkBoxComponent()}
        </>
      )
    },
    {
      target: '#searchBtn',
      disableOverlayClose: true,
      title: 'Search',
      content: (
        <>
          <TutorialText>
            Click the <b>Search</b> button to search. You will go to a page that shows national and state legislation
            that match your search choices.
          </TutorialText>

          {checkBoxComponent()}
        </>
      )
    }
  ]

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      run={isHomeTut ? homePageOpen : resultsPageOpen}
      stepIndex={stepIndex}
      showProgress
      disableScrolling
      disableScrollParentFix
      steps={isHomeTut ? HOMEPAGE_STEPS : RESULTS_STEPS}
      styles={{
        options: {
          primaryColor: 'red',
          zIndex: 2000,
          textAlign: 'left'
        }
      }}
    />
  )
}

Tutorial.propTypes = {
  /**
   * Determines which global state the help icon should update
   */
  isHomeTut: PropTypes.bool.isRequired
}
