/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, Grid, IconButton, Tooltip, Typography } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import { PolicyGlossary } from '@/utils/constants'

const useStyles = makeStyles((theme) => ({
  dialog: {
    background: 'rgb(34, 34, 44)'
  },
  icon: {
    color: 'white',
    fontSize: '1.3rem',
    cursor: 'pointer'
  },
  glossaryButton: {
    backgroundColor: 'rgb(30, 30, 38)',
    width: '3.3rem',
    height: '3.3rem',
    borderColor: 'white',
    cursor: 'pointer',
    margin: 0,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  iconButton: {
    color: '#fff',
    marginLeft: '5%'
  },
  closeIcon: {
    fontSize: '1.4rem'
  },
  description: {
    fontSize: '0.8rem',
    textAlign: 'left'
    // paddingLeft: '2rem'
  },
  title: {
    fontSize: '2rem',
    textAlign: 'center',
    paddingBottom: '20px'
  },
  definitions: {
    fontSize: '0.9rem',
    textAlign: 'left'
  },
  terms: {
    textAlign: 'right',
    fontSize: '0.9rem'
  },
  dividerTitle: {
    color: 'white',
    fontSize: '1rem',
    letterSpacing: '0.2em',
    fontWeight: 400,
    textAlign: 'center'
  },

  leftDivider: {
    marginTop: '0.5rem',
    backgroundColor: 'white',
    marginLeft: '2rem',
    width: '90%'
  },
  rightDivider: {
    marginTop: '0.5rem',
    backgroundColor: 'white',
    marginRight: '2rem',
    width: '90%'
  }
}))

const styles = (theme) => ({
  root: {
    background: 'rgb(34, 34, 44)',
    margin: 0,
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  }
})

/**
 * Glossary component that reperesnt the data
 * dictionary users see within both the State
 * and National tabs. Used as a pop-up to define
 * key terms within the application. Users can refer
 * throughout during their time.
 * @component
 */
const Glossary = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const tooltipTitle = 'Glossary'

  const handleTooltipClose = () => setOpen(false)

  const handleTooltipOpen = () => setOpen(true)

  const DialogTitle = withStyles(styles)((props) => {
    const { classes, onClose } = props
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <div style={{ paddingBottom: 10 }}>
          <Typography variant='h3' className={classes.title}>
            {tooltipTitle}
          </Typography>
        </div>
        {onClose ? (
          <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  })

  return (
    <div id='dataDictionary'>
      <Tooltip title='Glossary'>
        <IconButton className={classes.glossaryButton} onClick={handleTooltipOpen}>
          <FontAwesomeIcon color='RGB(255,255,255)' icon={faBook} className={classes.icon} />
        </IconButton>
      </Tooltip>

      <Dialog scroll='paper' onClose={handleTooltipClose} open={open} fullWidth maxWidth='md'>
        <DialogTitle onClose={handleTooltipClose} className={classes.dialog} />
        <DialogContent className={classes.dialog}>
          {PolicyGlossary !== null ? (
            <Grid container>
              {PolicyGlossary.map((policyItem, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={4} style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '35px' }}>
                    <Typography className={classes.terms}>{policyItem.title}</Typography>
                  </Grid>
                  <Grid item xs={8} style={{ paddingTop: '1rem' }}>
                    <Typography className={classes.definitions}>{policyItem.description}</Typography>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          ) : null}
        </DialogContent>
        <DialogActions className={classes.dialog} />
      </Dialog>
    </div>
  )
}

export default Glossary
