import { makeStyles } from '@material-ui/core'

import cdcLogo from '@/assets/images/cdclogo.svg'
import dcpclogo from '@/assets/images/dcpc_logo.png'

const useStyles = makeStyles(() => ({
  cdcBar: {
    background: '#ffffff',
    boxShadow: 'none',
    padding: '15px 31px',
    display: 'block'
  },
  appBar: {
    background: 'transparent',
    boxShadow: 'none',
    padding: '15px 15px 0'
  },
  cdcLogo: {
    height: '100%'
  },
  logo: {
    width: 150,
    height: 70
  }
}))

/**
 * Displays the CDC Header
 * @component
 */
export default function HeaderCDC() {
  const classes = useStyles()

  return (
    <>
      <div className={classes.cdcBar}>
        <a href='https://www.cdc.gov/'>
          <img className={classes.cdcLogo} src={cdcLogo} aria-label='CDC Logo' alt='CDC Logo' />
        </a>
      </div>
      <div className={classes.appBar}>
        <a href='https://www.cdc.gov/cancer'>
          <img className={classes.logo} src={dcpclogo} aria-label='DCPC Logo' alt='DCPC Logo' />
        </a>
      </div>
    </>
  )
}
